import React from "react"

const Taglist = ({ tags }) => {
  return (
    <div className="taglist">
      {tags.map(tag => (
        <div key={tag} className="tag">
          {tag}
        </div>
      ))}
    </div>
  )
}

export default Taglist
