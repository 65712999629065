import React from "react"
import Container from "./container"

const Section = ({ children, bg, color, title, id, customStyle }) => {
  const style = {
    background: bg,
    color: color,
    position: "relative",
    ...customStyle,
  }

  const titleEl = title ? (
    <h1 style={{ marginBottom: "3rem", textAlign: "center" }}>{title}</h1>
  ) : null

  return (
    <section className="section" style={style} id={id}>
      <Container>
        {titleEl}
        {children}
      </Container>
    </section>
  )
}

export default Section
