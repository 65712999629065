import React from "react"

// Note make sure the parent element has postion: relative
// This is set on the section component.

const Background = ({ imgUrl, bgColor }) => {
  return (
    <div className="background">
      <div className="background-image"></div>
      <div className="background-overlay"></div>
    </div>
  )
}

export default Background
