import React from "react"
import { Link } from "gatsby"
import logo from "../../static/images/logo/vector/isolated-monochrome-dark.svg"

const Navigation = () => {
  return (
    <nav className="header-nav">
      <ul>
        <li className="logo">
          <Link className="logo-link" to="/" style={{ display: "flex" }}>
            <img className="logo-image" src={logo} alt="logo" />
            <p className="logo-text">
              <em>Patrick Touchette</em>
            </p>
          </Link>
        </li>
        <li>
          <Link to="/blog" className="blog-link">
            Blog
          </Link>
        </li>
        <li>
          <Link to="/#about">À propos</Link>
        </li>
        <li>
          <Link to="/#portfolio">Portfolio</Link>
        </li>
        {/* <li>
          <Link to="/#pricing">Tarifs</Link>
        </li> */}
        <li>
          <Link to="/#contact" className="btn btn-primary">
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default Navigation
