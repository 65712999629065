import React from "react"

const Container = ({ children }) => {
  const style = {
    maxWidth: "1050px",
    margin: "0 auto",
    padding: "3rem 2rem",
    width: "100%",
  }

  return (
    <div className="container" style={style}>
      {children}
    </div>
  )
}

export default Container
