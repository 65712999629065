const colors = {
  primary: "#3065f8" /* Bright Blue '#3065f8',*/,
  secondary: "#585989" /* Desaturated Blue */,
  accent: "#3c9f8f" /* Moderate Cyan */,
  neutral: "#f6f6fe" /* Light Grayish Blue */,
  dark: "#070439" /* Very Dark Blue */,
  light: "#bfbfbf" /* Light Gray */,
}

export default colors
