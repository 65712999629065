import React from "react"
import Helmet from "react-helmet"
import favicon from "../../static/images/logo/favicon-32x32.png"
import ogImage from "../../static/images/patricktouchette.jpg"

const SEO = ({ pathname, lang, title, description }) => {
  if (pathname === undefined) {
    throw console.error("DEFINE CANONICAL PATH")
  }

  const siteUrl = "https://patricktouchette.com"

  const titleTemplate = title
    ? `${title} | ${"Patrick Touchette"}`
    : "Patrick Touchette | Développeur Web"

  return (
    <Helmet>
      <html lang={lang || "en"} />
      <link rel="canonical" href={`${siteUrl}${pathname}`} />
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta http-equiv="X-UA-Compatible" content="ie=edge" />

      <meta name="description" content={description} />
      <title>{titleTemplate}</title>
      <link rel="shortcut icon" href={favicon} type="image/x-icon" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:title" content={titleTemplate} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${siteUrl}${ogImage}`} />
      <meta property="og:image:width" content="512" />
      <meta property="og:image:height" content="512" />

      <meta name="twitter:card" content="summary" />
      {/* <meta name="twitter:site" content={twitter} /> */}
    </Helmet>
  )
}

SEO.defaultProps = {
  description:
    "Je crée des sites web pour les entrepreneurs, les PME et les travailleurs autonomes.",
}

export default SEO
