import React from "react"
import Social from "./social"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-contact">
        <h5 className="text">N'hésitez pas à me contacter.</h5>
        <p className="icon-container">
          <svg
            className="icon"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
          >
            <title>envelop</title>
            <path d="M29 4h-26c-1.65 0-3 1.35-3 3v20c0 1.65 1.35 3 3 3h26c1.65 0 3-1.35 3-3v-20c0-1.65-1.35-3-3-3zM12.461 17.199l-8.461 6.59v-15.676l8.461 9.086zM5.512 8h20.976l-10.488 7.875-10.488-7.875zM12.79 17.553l3.21 3.447 3.21-3.447 6.58 8.447h-19.579l6.58-8.447zM19.539 17.199l8.461-9.086v15.676l-8.461-6.59z"></path>
          </svg>
          info@patricktouchette.com
        </p>
        {/* <p className="icon-container">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            className="icon"
          >
            <title>phone</title>
            <path d="M22 20c-2 2-2 4-4 4s-4-2-6-4-4-4-4-6 2-2 4-4-4-8-6-8-6 6-6 6c0 4 4.109 12.109 8 16s12 8 16 8c0 0 6-4 6-6s-6-8-8-6z"></path>
          </svg>
          514-688-4983
        </p> */}
      </div>

      <Social />

      <p className="copyright">
        Patrick Touchette &copy; 2020 . Built in{" "}
        <span role="img" aria-label="mountain">
          🗻
        </span>{" "}
        Bromont, Quebec, Canada.
      </p>
    </footer>
  )
}

export default Footer
