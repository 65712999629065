import React from "react"
import Footer from "./footer"
import Section from "./section"
import Background from "./background"
import Navigation from "./navigation"
import colors from "../styles/colors.js"
const { dark } = colors

const style = {
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  justifyContent: "flex-start",
}

const Layout = ({ children }) => {
  return (
    <div className="layout" style={style}>
      <Section>
        <Background />
        <Navigation />
        <div style={{ marginBottom: "5rem" }}></div>
        {children}
      </Section>

      <Section
        bg={dark}
        color="white"
        customStyle={{ marginTop: "auto", justifySelf: "flex-end" }}
      >
        <Footer />
      </Section>
    </div>
  )
}

export default Layout
